import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@/components/Button';
import Card from '@/components/Card';
import { IStaticTableSummary } from '@/components/Installment/StaticTableSummary/index.d';
import { Caption, Title3 } from '@/components/Text';
import { IRELAND, UNITED_KINGDOM } from '@/constants/locales';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

export const StaticTableSummary = ({ amount, selectedInstallment }: IStaticTableSummary) => {
	const { t } = useTranslation('howitworks');

	const currencyFormat = useCurrencyFormat();
	const { push, locale = UNITED_KINGDOM } = useRouter();

	const InternalizationSummary = () => (
		<>
			<div className="col-span-1 mb-1 md:mb-0">
				<div className="block md:flex justify-center items-center gap-1.5">
					<div>
						<Caption weight="bold">{t('calculator.footer.total')}</Caption>
					</div>
					<div>
						<Title3 variant="reverse">
							{currencyFormat(amount ?? '00', {
								minimumFractionDigits: 2,
							})}
						</Title3>
					</div>
				</div>
			</div>
			<div className="col-span-1  mb-1 md:mb-0">
				<div className="block md:flex justify-center items-center gap-1.5">
					<div>
						<Caption weight="bold">{t('calculator.footer.fees')}</Caption>
					</div>
					<div>
						<Title3 variant="success">
							{currencyFormat('00', {
								minimumFractionDigits: 2,
							})}
						</Title3>
					</div>
				</div>
			</div>
		</>
	);

	return (
		<Card noBorder className="rounded-t-none -mt-1.5" structure="no-structure" padding="py-2">
			{selectedInstallment && (
				<div className="grid grid-cols-2 items-center border-t-2 border-tertiary mt-0.75 pt-1.5">
					{[UNITED_KINGDOM, IRELAND].includes(locale) ? (
						<>
							<div className="col-span-2 md:col-span-1 mb-1 md:mb-0">
								<div className="flex gap-2">
									<div>
										<Caption weight="bold">{t('calculator.footer.total')}</Caption>
										<Title3 variant="reverse">
											{currencyFormat(amount ?? '00', {
												minimumFractionDigits: 2,
											})}
										</Title3>
									</div>
									<div>
										<Caption weight="bold">{t('calculator.footer.fees')}</Caption>
										<Title3 variant="success">
											{currencyFormat('00', {
												minimumFractionDigits: 2,
											})}
										</Title3>
									</div>
								</div>
							</div>
							<div className="col-span-2 md:col-span-1 flex justify-center  md:justify-end">
								<Button
									size="medium"
									label={t('calculator.footer.button')}
									icon={faLongArrowRight}
									onClick={() => push('/enter-your-reg')}
								/>
							</div>
						</>
					) : (
						<InternalizationSummary />
					)}
				</div>
			)}
		</Card>
	);
};
