import { ArcElement, Chart as ChartJS } from 'chart.js';
import { FC, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';

import IDonut from '@/components/Chart/Donut/index.d';
import useBrowserMounted from '@/services/Hooks/useBrowserMounted';

ChartJS.register(ArcElement);

const Donut: FC<IDonut.IProps> = ({
	data = [],
	cutout = 50,
	colorPalette = ['reverse-content', 'tertiary-contrast'],
}) => {
	const isBrowserMounted = useBrowserMounted();
	const getColorCode = useRef((colorName: string) =>
		getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}`)
	);

	return isBrowserMounted ? (
		<Doughnut
			data={{
				datasets: [
					{
						backgroundColor: colorPalette.map((color) => getColorCode.current(color)),
						borderWidth: 0,
						// @ts-expect-error
						cutout,
						data,
					},
				],
				labels: [],
			}}
		/>
	) : null;
};
export default Donut;
