/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FC, FunctionComponent } from 'react';

import AttributedText from '@/components/AttributedText/';
import Icon from '@/components/Icon';
import { IconVariant, IInfoBox, Variant } from '@/components/InfoBox/index.d';
import { Body } from '@/components/Text';

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'success':
			return {
				background: 'bg-success',
				color: 'text-primary-content',
			};
		case 'warning':
			return {
				background: 'bg-warning',
				color: 'text-primary-content',
			};
		case 'error':
			return {
				background: 'bg-error',
				color: 'text-secondary-content',
			};
		case 'transparent':
			return {
				background: 'bg-transparent',
				color: 'text-accent-content',
			};
		case 'tone-contrast-5':
			return {
				background: 'bg-tone-contrast-5',
				color: 'text-accent-content',
			};
		case 'tertiary':
			return {
				background: 'bg-tertiary',
				color: 'text-primary',
			};
		case 'primary':
			return {
				background: 'bg-primary',
				color: 'text-secondary-content',
			};
		default:
			return {
				background: 'bg-warning',
				color: 'text-success',
			};
	}
};

const getIconVariant = (variant: IconVariant) => {
	switch (variant) {
		case 'secondary':
			return 'secondary';
		default:
			return 'primary';
	}
};

const InfoBox: FunctionComponent<IInfoBox.IProps> = ({
	text,
	variant = 'warning',
	icon,
	className,
	linkComp = '',
	dataCy,
	isHighlighted = false,
	header,
	iconVariant = 'primary',
	isHighlightedLink = false,
	linkHref = '',
	textClassName,
}) => {
	const { background, color } = getColor(variant);

	const isInline = linkComp ? 'inline mr-0.25' : '';

	const GetHighlightedLink: FC<IInfoBox.IHighlightedLink> = ({
		href,
		linkText,
		textColor,
		dataCy,
	}) => (
		<AttributedText
			wrapper="link"
			dataCy={dataCy}
			wrapperProps={{
				className: `inline-block ${textColor} underline`,
				external: true,
				href,
				target: 'blank',
				weight: 'bold',
			}}
			className="text-secondary-content bold"
		>
			{linkText}
		</AttributedText>
	);
	return (
		<div
			data-cy={`${dataCy}Container`}
			className={`transition-all items-center flex p-1 rounded-3xl ${background} ${className}`}
		>
			{icon && (
				<div data-cy={`${dataCy}IconContainer`} className="flex mr-0.75">
					<Icon iconName={icon} variant={getIconVariant(iconVariant)} />
				</div>
			)}
			<div>
				{(header || null) && (
					<Body data-cy={`${dataCy}Title`} weight="bold">
						{header}
					</Body>
				)}
				{isHighlighted ? (
					<AttributedText
						wrapper={Body}
						dataCy={`${dataCy}HighlightedText`}
						wrapperProps={{
							className: `${color} ${isInline}`,
							weight: 'bold',
						}}
						textClassName="flex gap-x-0.25"
					>
						{text}
					</AttributedText>
				) : isHighlightedLink ? (
					<GetHighlightedLink
						dataCy={`${dataCy}HighlightedLink`}
						linkText={text}
						href={linkHref}
						textColor={color}
					/>
				) : (
					<Body data-cy={`${dataCy}Text`} className={`${color} ${isInline} ${textClassName}`}>
						{text}
					</Body>
				)}
				{linkComp && linkComp}
			</div>
		</div>
	);
};

export const AnimatedInfoBox: FunctionComponent<IInfoBox.IAnimated> = ({
	header,
	variant = 'primary',
	className,
	isInfo,
	text,
}) => {
	const { background, color } = getColor(variant);

	return (
		<div className="relative h-10">
			<div
				className={`group absolute w-full bg-reverse-content h-10 rounded-xl ${background} ${className}  outline-none transition-all duration-500 ease-in-out focus:shadow-outline hover:h-40`}
			>
				<div className="absolute -top-2 -right-2">
					{isInfo && (
						<div className="mr-0.25 position -right-0 top-10">
							<Icon
								iconName={faQuestionCircle}
								variant="primary"
								className="bg-reverse rounded-md"
							/>
						</div>
					)}
				</div>
				<div className=" p-0.5 ">
					<div className="flex items-center opacity-100 outline-none transition-all  ease-in-out focus:shadow-outline group-hover:opacity-0">
						<Icon iconName={faLink} variant="secondary" className="mr-0.5" />
						<Body variant="reverse-base" className={`${color}`}>
							{header}
						</Body>
					</div>
					<div className="h-full p-0.5 opacity-0 outline-none transition-all duration-700 ease-in-out focus:shadow-outline group-hover:opacity-100">
						<div className="flex justify-center">
							<Body variant="reverse-base" className={`${color} text-center`}>
								{text}
							</Body>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InfoBox;
