import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, Fragment } from 'react';

import InfoBox from '@/components/InfoBox';
import { OnlineRepaySummaryTypes } from '@/components/Installment/OnlineRepaySummary/index.d';
import { Body } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

const OnlineRepaySummary: FC<OnlineRepaySummaryTypes.IRepayment> = ({ data }) => {
	const { t } = useTranslation('customer');
	const currencyFormat = useCurrencyFormat();

	return (
		<>
			{data ? (
				<div
					className={`my-0.75 space-y-1 ${
						data?.is_in_arrears ? '' : 'border-t border-tertiary-contrast'
					}`}
				>
					<InfoBox
						className={data?.is_in_arrears ? '' : 'py-0 h-0 overflow-hidden'}
						text={
							data?.arrears_fee_added
								? t('onlineRepay.table.fee.added', {
										amount: currencyFormat(data?.in_arrears_fee || 0, {
											minimumFractionDigits: 2,
										}),
								  })
								: t('onlineRepay.table.fee.willAdd', {
										amount: currencyFormat(data?.in_arrears_fee || 0, {
											minimumFractionDigits: 2,
										}),
								  })
						}
						icon={faInfoCircle}
						isHighlighted
					/>
					{[
						{
							label: t('onlineRepay.table.installment.repaid'),
							value: data?.total_paid,
						},
						{
							isVisible: data?.is_in_arrears,
							label: t('onlineRepay.table.installment.overdue'),
							value: data?.total_overdue,
						},
						{
							label: t('onlineRepay.table.installment.outstanding'),
							value: data?.total_outstanding,
							weight: 'bold',
						},
					].map(({ label, value, weight = 'content', isVisible = true }: any) => (
						<Fragment key={label}>
							{isVisible && (
								<div className="flex justify-between">
									<Body weight={weight}>{label}</Body>
									<Body weight={weight}>
										{currencyFormat(value ?? '00', {
											minimumFractionDigits: 2,
										})}
									</Body>
								</div>
							)}
						</Fragment>
					))}
				</div>
			) : null}
		</>
	);
};

export default OnlineRepaySummary;
