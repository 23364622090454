import { useTranslation } from 'next-i18next';

import { ITotalAmount } from '@/components/Installment/TotalAmount/index.d';
import { Caption, LargeBody } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

export const TotalAmount = ({ hideDonut, displayTotalAmount, currency }: ITotalAmount) => {
	const currencyFormat = useCurrencyFormat(currency);
	const { t } = useTranslation('common');

	return (
		<>
			{displayTotalAmount ? (
				<div className={`${hideDonut ? 'mt-1' : 'mt-1 md:mt-5'}`}>
					<hr className="text-tertiary-contrast" />
					<div className="mt-1 md:mt-1.5">
						<Caption weight="bold" className="flex flex-row justify-between">
							{displayTotalAmount.text ? (
								<LargeBody weight="bold">{displayTotalAmount.text}</LargeBody>
							) : (
								<span>{t('totalAmount')}</span>
							)}

							{displayTotalAmount.text ? (
								<LargeBody weight="bold">
									{currencyFormat(displayTotalAmount.total, {
										minimumFractionDigits: 2,
									})}
								</LargeBody>
							) : (
								<span>
									{currencyFormat(displayTotalAmount.total, {
										minimumFractionDigits: 2,
									})}
								</span>
							)}
						</Caption>
					</div>
				</div>
			) : null}
		</>
	);
};
