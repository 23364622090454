import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, useMemo, useState } from 'react';
import Collapsible from 'react-collapsible';

import Card from '@/components/Card';
import Icon from '@/components/Icon';
import IInstallment, { InstallmentTableType } from '@/components/Installment/index.d';
import { MonthlyPayment } from '@/components/Installment/MonthlyPayment';
import OnlineRepaySummary from '@/components/Installment/OnlineRepaySummary';
import { PaymentDate } from '@/components/Installment/PaymentDate';
import InstallmentRow from '@/components/Installment/Row';
import { StaticTableSummary } from '@/components/Installment/StaticTableSummary';
import InstallmentStep from '@/components/Installment/Step';
import { TableSummary } from '@/components/Installment/TableSummary';
import { TotalAmount } from '@/components/Installment/TotalAmount';
import { Body } from '@/components/Text';

const chunk = (data: IInstallment.IChartData[], size: number) => {
	const chunkSet = [];
	for (let i = 0; i < data.length; i += size)
		chunkSet.push({ data: data.slice(i, i + size), id: i });
	return chunkSet;
};

const InstallmentTable: FC<IInstallment.IInstallmentTable> = ({
	set = [],
	header,
	className,
	tableSummary,
	currency,
	hideDonut,
	variant = 'tone-contrast-5',
	isPaid = false,
	spacing,
	colorPalette,
	displayTotalAmount,
	displayMonthlyPayment,
	changePaymentDate,
	handleChangePaymentDate,
	collapsible = false,
	type = InstallmentTableType.NONE,
	onlineRepaySummary, // uses for online repay table summary
	selectedProduct,
	amount,
	structure = 'rounded-custom-2.5',
	showStaticTableSummary = true,
}) => {
	const { t } = useTranslation('common');
	const chunks = useMemo<IInstallment.IChunkData[]>(() => chunk(set, 5), [set]);
	const [open, setOpen] = useState<boolean>(!collapsible);

	if (chunks.length === 0) {
		return null;
	}

	const getSpacing = (space: any) => {
		switch (space) {
			case 'small':
				return changePaymentDate
					? 'px-1 pt-1.5 pb-0 md:px-2 md:pb-0'
					: 'px-1 pt-1.5 pb-0 md:px-2 md:pb-0';
			case 'medium':
				return changePaymentDate
					? 'px-1 pt-1.5 pb-0 md:px-2 md:pb-0'
					: 'px-1 pt-1.5 pb-0 md:px-0 md:pb-0';
			default:
				return changePaymentDate ? 'p-1 md:p-2' : 'px-1 py-1 md:px-2 md:py-2 md:pb-0';
		}
	};

	return (
		<Card
			variant={variant}
			className={`${tableSummary ? 'md:pb-1' : `${getSpacing(spacing)}`} ${className}`}
			noBorder
			structure={structure}
		>
			<Collapsible
				trigger={
					<div data-cy="installmentTable">
						{collapsible && (
							<div className="flex flex-col md:flex-row md:justify-between mb-1">
								<Body weight="bold">{header}</Body>
								<Body className="mt-0.5 md:mt-0">
									{open ? t('hideDetails') : t('viewDetails')}
									<Icon className="ml-0.5" iconName={open ? faChevronUp : faChevronDown} />
								</Body>
							</div>
						)}
					</div>
				}
				open={open}
				handleTriggerClick={() => setOpen(!open)}
			>
				{header && !collapsible && (
					<Body className="mb-1.5" weight="bold">
						{header}
					</Body>
				)}
				<div className={`${hideDonut ? 'hidden' : 'hidden md:block'}`}>
					{chunks.map((dataSet, idx) => (
						<InstallmentRow
							isPaid={isPaid && idx === 0}
							key={dataSet.id}
							total={set.length}
							rowData={dataSet.data}
							chartSize={set.length < 4 ? 'md' : 'sm'}
							className={idx > 0 ? 'mt-2' : ''}
							colorPalette={colorPalette}
							type={type}
						/>
					))}
				</div>
				<div className={`${hideDonut ? 'block' : 'md:hidden'}`}>
					<InstallmentStep paid={isPaid} currency={currency} data={set} />
				</div>
				<PaymentDate
					changePaymentDate={changePaymentDate}
					handleChangePaymentDate={handleChangePaymentDate}
				/>
				<TotalAmount
					hideDonut={hideDonut}
					displayTotalAmount={displayTotalAmount}
					currency={currency}
				/>
				<MonthlyPayment
					set={set}
					hideDonut={hideDonut}
					currency={currency}
					displayMonthlyPayment={displayMonthlyPayment}
				/>
				<TableSummary
					tableSummary={tableSummary}
					currency={currency}
					hideDonut={hideDonut}
					set={set}
					collapsible={collapsible}
				/>
				{amount && selectedProduct && showStaticTableSummary ? (
					<StaticTableSummary amount={amount} selectedInstallment={selectedProduct} />
				) : null}

				<OnlineRepaySummary data={onlineRepaySummary} />
			</Collapsible>
		</Card>
	);
};

export default InstallmentTable;
