import { useTranslation } from 'next-i18next';

import { ITableSummary } from '@/components/Installment/TableSummary/index.d';
import { Caption } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

export const TableSummary = ({
	set = [],
	tableSummary,
	currency,
	hideDonut,
	collapsible = false,
}: ITableSummary) => {
	const { t } = useTranslation('common');
	const currencyFormat = useCurrencyFormat(currency);

	return (
		<>
			{tableSummary && (
				<div className={`${hideDonut ? 'mt-1' : 'mt-3'}`}>
					<hr
						className={set.length < 4 ? 'text-tone-contrast-30 mt-4' : 'text-tone-contrast-30 mt-0'}
					/>
					<div className="flex flex-col space-y-0.75 mt-1">
						{tableSummary.borrow ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('interestAndFees')}</span>
								<span>
									{currencyFormat(tableSummary.borrow, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}

						{tableSummary?.fees !== undefined && tableSummary?.fees >= 0 && collapsible ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('interestAndFees')}</span>
								<span>
									{currencyFormat(0, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
						{tableSummary.paidDeposit ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('depositPaid')}</span>
								<span>
									{currencyFormat(tableSummary.paidDeposit, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
						{tableSummary.depositDueToday ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('depositDueToday')}</span>
								<span>
									{currencyFormat(tableSummary.depositDueToday, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
						{tableSummary.amountOutstanding ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('amountOutstanding')}</span>
								<span>
									{currencyFormat(tableSummary.amountOutstanding, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
						{tableSummary.totalAmountOutstanding ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('totalAmountOutstanding')}</span>
								<span>
									{currencyFormat(tableSummary.totalAmountOutstanding, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
						{tableSummary.total ? (
							<Caption weight="bold" className="flex flex-row justify-between">
								<span>{t('totalPayable')}</span>
								<span>
									{currencyFormat(tableSummary.total, {
										minimumFractionDigits: 2,
									})}
								</span>
							</Caption>
						) : null}
					</div>
				</div>
			)}
		</>
	);
};
