import { useTranslation } from 'next-i18next';

import { IPaymentDate } from '@/components/Installment/PaymentDate/index.d';
import { Caption } from '@/components/Text';

export const PaymentDate = ({ changePaymentDate, handleChangePaymentDate }: IPaymentDate) => {
	const { t } = useTranslation('common');

	return (
		<>
			{changePaymentDate && (
				<div className="flex justify-center mt-1 md:mt-2 lg:mt-3">
					<Caption
						variant="success"
						className="underline hover:cursor-pointer"
						onClick={handleChangePaymentDate}
					>
						{t('integration:landing.payLater.changePaymentDate')}
					</Caption>
				</div>
			)}
		</>
	);
};
