import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Caption } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

export const OnlineRepayInstallmentDonutLabel: FC<any> = ({ paid, status, overdue }) => {
	const { t } = useTranslation('customer');
	const currencyFormat = useCurrencyFormat();

	return (
		<>
			{status === 'settled' && (
				<Caption className="mt-1" weight="bold">
					{t('onlineRepay.table.installment.paid', {
						amount: currencyFormat(paid, {
							minimumFractionDigits: 2,
						}),
					})}
				</Caption>
			)}
			{status === 'arrears' && (
				<Caption className="mt-1" weight="bold">
					{t('onlineRepay.table.installment.overdueBy', {
						day: overdue,
						separator: '\n',
					})}
				</Caption>
			)}
			{status === 'pending' && (
				<Caption className="mt-1">
					{paid
						? t('onlineRepay.table.installment.paidDate', {
								amount: currencyFormat(paid, {
									minimumFractionDigits: 2,
								}),
						  })
						: t('applications:badge.pending')}
				</Caption>
			)}
		</>
	);
};
