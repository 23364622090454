import { FC } from 'react';

import IStepper from '@/components/Stepper/index.d';
import { Caption, Title1 } from '@/components/Text';

const getColor = (paid: boolean | undefined, overdue: number | undefined) => {
	if (paid) return 'bg-success';
	if (overdue) return 'bg-error';
	return 'bg-primary';
};

const Stepper: FC<IStepper.IProps> = ({ itemList = [] }) => (
	<div className="flex flex-col">
		{itemList.map(({ id, content, title, paid, overdue, subContent, code }, idx) => (
			<div className="flex gap-x-0.5" key={id}>
				<div className="flex flex-col items-center">
					<Caption
						className={`flex items-center justify-center w-6 min-w-1.5 h-6 min-h-1.5 border border-tone-contrast rounded-full 
								${getColor(paid, overdue)}`}
						weight="bold"
					>
						{id}
					</Caption>
					{itemList.length > idx + 1 && <div className="w-px h-full bg-tone-contrast" />}
				</div>
				<div className={itemList.length > idx + 1 ? 'pb-1' : ''}>
					{title ? <p className="font-sans font-semibold">{title}</p> : null}
					<>{content}</>
					{code ? (
						<Title1 id="bumperCode" variant="reverse">
							{code}
						</Title1>
					) : null}
					{subContent ? <Caption>{subContent}</Caption> : null}
				</div>
			</div>
		))}
	</div>
);

export default Stepper;
