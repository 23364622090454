import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';

import Donut from '@/components/Chart/Donut';
import Icon from '@/components/Icon';
import IInstallment, { InstallmentTableType } from '@/components/Installment/index.d';
import { OnlineRepayInstallmentDonutLabel } from '@/components/Installment/OnlineRepayInstallmentDonutLabel';
import { Caption } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

const getSize = (size: IInstallment.Size) => {
	switch (size) {
		case 'md':
			return { container: 'w-32', cutout: 50 };
		case 'sm':
		default:
			return { container: 'w-24', cutout: 38 };
	}
};

const getColor = (status: string, palette?: string[]) => {
	switch (status) {
		case 'settled':
			return ['success', 'tertiary-contrast'];
		case 'arrears':
			return ['error-contrast', 'tertiary-contrast'];
		case 'pending':
		default:
			return palette || ['reverse-content', 'tertiary-contrast'];
	}
};

const InstallmentDonut: FC<IInstallment.IInstallmentDonut> = ({
	id,
	size = 'md',
	total,
	amount,
	sumAmount,
	date,
	isPaid = false,
	colorPalette,
	type,
	title,
	status,
	overdue,
	paidAmount,
}) => {
	const { t } = useTranslation('common');
	const currencyFormat = useCurrencyFormat();
	const sizeValues = useMemo(() => getSize(size), [size]);

	return (
		<div className={`text-center relative ${sizeValues.container}`}>
			{type !== InstallmentTableType.ONLINE_REPAY ? (
				<Caption className="mb-1.5 h-4" weight="bold">
					{title || t('payment.title', { instalment: id })}
				</Caption>
			) : (
				<Caption className="mb-1" weight="bold">
					{t('payment.title', { instalment: id })}
					<br />
					{date}
				</Caption>
			)}

			<div className="flex items-center justify-center">
				<Donut
					data={[sumAmount, Math.max(total - sumAmount, 0)]}
					cutout={sizeValues.cutout}
					colorPalette={getColor(status || '', colorPalette)}
				/>
				<Caption className="mx-auto w-full absolute">
					{currencyFormat(amount, {
						minimumFractionDigits: 2,
					})}
				</Caption>
			</div>

			<div>
				{type !== InstallmentTableType.ONLINE_REPAY ? (
					<Caption className="mt-1 h-4 mb-1" weight="bold">
						{date}
					</Caption>
				) : (
					<OnlineRepayInstallmentDonutLabel paid={paidAmount} overdue={overdue} status={status} />
				)}

				{isPaid ? <Icon iconName={faCheckCircle} variant="success" className="text-n-1" /> : null}
			</div>
		</div>
	);
};
export default InstallmentDonut;
