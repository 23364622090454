import { ReactNode } from 'react';

import IButtonGroup from '@/components/ButtonGroup';
import { Structure } from '@/components/Card/index.d';
import { ColorPalette } from '@/components/Chart/Donut/index.d';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import { IUseCurrency } from '@/services/Hooks/useCurrencyFormat';

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'reverse' | 'tone-contrast-5';

export enum InstallmentTableType {
	NONE = 'none',
	ONLINE_REPAY = 'paid',
}
declare namespace IInstallment {
	type Size = 'md' | 'sm';

	interface IChartData {
		id: number | string;
		date: string;
		amount: number;
		total: number;
		sumAmount: number;
		title?: string;
		status?: string;
		overdue?: number;
		paid?: number;
		outstanding: number;
	}

	interface IInstallmentDonut {
		id: number | string;
		date: string;
		amount: number;
		total: number;
		sumAmount: number;
		title?: string;
		status?: string;
		overdue?: number;
		paid?: number;
		size?: Size;
		isPaid?: boolean;
		colorPalette?: ColorPalette;
		title?: string;
		type?: InstallmentTableType;
		overdue?: number;
		paidAmount?: number;
	}

	interface IProps {
		selectedValue?: number;
		products: IButtonGroup.IItem[];
		set: IInstallmentDonut.IChartData[];
		onChange?: (e) => void;
		deposit?: number;
		tableTitle?: string;
		variant?: Variant;
		className?: string;
		spacing?: string;
		colorPalette?: ColorPalette;
	}

	interface IDynamicProps {
		selectedProduct?: IStoreAndAPIShared.IProduct;
		products?: IStoreAndAPIShared.IProduct[];
		showProducts?: boolean;
		set: IInstallmentDonut.IChartData[];
		onChange?: (product: IStoreAndAPIShared.IProduct) => void;
		tableTitle?: string;
		currency?: IUseCurrency;
		error?: boolean;
		title?: string;
		showInfoBox?: boolean;
		showMaxTooltips?: boolean;
		amount?: number;
		changePaymentDate?: boolean;
		handleChangePaymentDate?: () => void;
		tableSummary?: {
			borrow?: number;
			fees?: number;
			total?: number;
			paidDeposit?: string;
			amountOutstanding?: string;
			depositDueToday?: number;
		};
		infoText?: string;
		colorPalette?: ColorPalette;
		variant?: Variant;
		collapsible?: boolean;
		type?: InstallmentTableType;
		spacing?: string;
		structure?: Structure;
		showStaticTableSummary?: boolean;
	}

	interface IChunkData {
		data: IInstallmentDonut.IChartData[];
		id: string | number;
	}

	interface IInstallmentTable {
		variant?: Variant;
		className?: string;
		title?: string;
		header?: string;
		tableSummary?: {
			borrow?: number;
			fees?: number;
			total?: number;
			paidDeposit?: string;
			amountOutstanding?: string;
			depositDueToday?: number;
			totalAmountOutstanding?: string;
			repaid?: number;
		};
		set: IInstallmentDonut.IChartData[];
		footer?: ReactNode;
		currency?: IUseCurrency;
		hideDonut?: boolean;
		isPaid?: boolean;
		amount?: number;
		spacing?: string;
		colorPalette?: ColorPalette;
		changePaymentDate?: boolean;
		handleChangePaymentDate?: () => void;
		selectedProduct?: IStoreAndAPIShared.IProduct;
		displayTotalAmount?: {
			total?: number;
			text?: string;
		};
		displayMonthlyPayment?: {
			total?: string;
		};
		collapsible?: boolean;
		staticFooter?: boolean;
		type?: InstallmentTableType;
		onlineRepaySummary?: {
			total_paid: number;
			total_overdue: number;
			total_outstanding: number;
			arrears_fee_added: boolean;
			total_feeadded_amount: any;
			is_in_arrears: boolean;
			in_arrears_fee: number;
			customer_name: string;
			min: number;
			max: number;
			dealership_id: number;
		};
		structure?: Structure;
		showStaticTableSummary?: boolean;
	}

	interface IInstallmentStep {
		data: {
			id: number | string;
			date: string;
			amount: number;
			paid: boolean;
			overdue: number;
		}[];
		currency?: IUseCurrency;
		paid?: boolean;
	}

	interface IInstallmentRow {
		total: number;
		rowData: IInstallmentDonut.IChartData[];
		className?: string;
		chartSize?: IInstallmentDonut.Size;
		isPaid?: boolean;
		colorPalette?: ColorPalette;
		type?: InstallmentTableType;
	}
}

export default IInstallment;
