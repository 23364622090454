import { FC, Fragment } from 'react';

import InstallmentDonut from '@/components/Installment/Donut';
import IInstallment, { InstallmentTableType } from '@/components/Installment/index.d';
import { Body } from '@/components/Text';

const InstallmentRow: FC<IInstallment.IInstallmentRow> = ({
	rowData,
	className,
	chartSize,
	isPaid,
	colorPalette,
	type,
}) => (
	<div
		className={`gap-y-2  justify-items-center content-center flex py-0.5 ${
			rowData.length > 3 ? 'justify-between gap-x-0.5' : 'justify-center gap-x-2'
		} ${className}`}
	>
		{rowData.map(
			(
				{
					id,
					total,
					amount,
					sumAmount,
					date,
					title,
					outstanding,
					status,
					overdue,
					paid,
				}: IInstallment.IChartData,
				idx: number
			) => (
				<Fragment key={id}>
					<InstallmentDonut
						id={id}
						total={total}
						amount={type === InstallmentTableType.ONLINE_REPAY ? outstanding : amount}
						sumAmount={sumAmount}
						date={date}
						size={chartSize}
						isPaid={isPaid || outstanding === 0}
						colorPalette={colorPalette}
						title={title}
						status={status}
						type={type}
						overdue={overdue}
						paidAmount={paid}
					/>
					{rowData.length > 1 && rowData.length > idx + 1 && (
						<Body className="flex items-center">+</Body>
					)}
				</Fragment>
			)
		)}
	</div>
);

export default InstallmentRow;
