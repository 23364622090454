/* eslint-disable consistent-return */
import { useTranslation } from 'next-i18next';

import { IMonthlyPayment } from '@/components/Installment/MonthlyPayment/index.d';
import { Body } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';
import { getSingleInstalmentAmount } from '@/services/Utils/Installment';

export const MonthlyPayment = ({
	set,
	hideDonut,
	currency,
	displayMonthlyPayment,
}: IMonthlyPayment) => {
	const { t } = useTranslation('common');
	const currencyFormat = useCurrencyFormat(currency);
	const singleInstalmentAmount = getSingleInstalmentAmount(set);

	return (
		<>
			{displayMonthlyPayment ? (
				<div className={`${hideDonut ? 'mt-1' : 'mt-1 md:mt-5'}`}>
					<hr className="text-tertiary-contrast" />
					<div className="mt-1 md:mt-1.5">
						<div className="flex flex-row justify-between md:justify-center mb-1.5 md:mb-2.5">
							<div className="flex flex-col mr-1">
								<Body className="w-max" weight="content">
									{t('integration:common.monthlyPayments.payments', {
										numberOfPayments: set.length,
									})}
								</Body>
								<Body weight="bold">
									{currencyFormat(singleInstalmentAmount, { minimumFractionDigits: 2 })}
								</Body>
							</div>
							<div className="flex flex-col">
								<Body weight="content">{t('integration:common.monthlyPayments.vatText')}</Body>
								<Body weight="bold">
									{currencyFormat(displayMonthlyPayment?.total, { minimumFractionDigits: 2 })}
								</Body>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};
