import { faCheckCircle, faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FC, useMemo } from 'react';

import Icon from '@/components/Icon';
import IInstallment from '@/components/Installment/index.d';
import Stepper from '@/components/Stepper';
import { Body, Caption } from '@/components/Text';
import useCurrencyFormat from '@/services/Hooks/useCurrencyFormat';

const InstallmentStep: FC<IInstallment.IInstallmentStep> = ({ data, currency }) => {
	const currencyFormat = useCurrencyFormat(currency);
	const itemList = useMemo(() => {
		if (!data || !currencyFormat) return [];
		return data.map(({ id, amount, date, paid, overdue }) => ({
			content: (
				<>
					<div className="flex flex-row space-x-0.5 items-center">
						<Body weight="bold">
							{currencyFormat(amount, {
								minimumFractionDigits: 2,
							})}
						</Body>
						{paid ? (
							<Icon iconName={faCheckCircle} variant="success" className="text-n2 pb-0.25" />
						) : null}
						{overdue ? (
							<Icon iconName={faDiamondExclamation} variant="error" className="text-n2 pb-0.25" />
						) : null}
					</div>
					<Caption variant="contrast-50">{date}</Caption>
				</>
			),
			id,
			overdue,
			paid,
		}));
	}, [currencyFormat, data]);

	return <Stepper itemList={itemList} />;
};

export default InstallmentStep;
