import { FC } from 'react';

import { ICard, Structure, Variant } from '@/components/Card/index.d';

const getColor = (variant: Variant) => {
	switch (variant) {
		case 'primary':
			return 'bg-primary';
		case 'secondary':
			return 'bg-secondary';
		case 'secondary-contrast':
			return 'bg-secondary-contrast';
		case 'tertiary':
			return 'bg-tertiary';
		case 'accent':
			return 'bg-accent';
		case 'accent-contrast':
			return 'bg-accent-contrast';
		case 'tone-contrast-5':
			return 'bg-tone-contrast-5';
		case 'reverse-content':
			return 'bg-reverse-content';
		case 'tone-contrast':
			return 'bg-tone-contrast';
		case 'tertiary-contrast':
			return 'bg-tertiary-contrast';
		case 'reverse':
			return 'bg-reverse';
		case 'success-contrast':
			return 'bg-success-contrast';
		case 'transparent':
			return 'bg-transparent';
		default:
			return 'bg-reverse';
	}
};

const getStyle = (structure: Structure) => {
	switch (structure) {
		case 'x-small':
			return 'rounded-0';
		case 'x-small-rounded':
			return 'rounded-3xl';
		case 'small':
			return 'rounded-3xl p-1.5';
		case 'rounded-small':
			return 'rounded-full p-1';
		case 'rounded-medium':
			return 'rounded-3xl py-2.5 px-1.5';
		case 'rounded-large':
			return 'rounded-3xl py-1 px-1.5';
		case 'full-rounded':
			return 'rounded-3xl';
		case 'rounded-custom-2.5':
			return 'rounded-2.5';
		case 'rounded-custom-2':
			return 'rounded-2';
		case 'rounded-custom-1':
			return 'rounded-1';
		case 'rounded-custom-2xl':
			return 'rounded-2xl';
		case 'rounded-responsive-v1':
			return 'rounded-1 md:rounded-1.5';
		case 'rounded-responsive-v1.5':
			return 'rounded-2xl md:rounded-2.5';
		case 'rounded-responsive-v2':
			return 'rounded-2 md:rounded-2.5';
		case 'rounded-responsive-v2.1':
			return 'rounded-2xl md:rounded-2';
		case 'rounded-bottom':
			return 'rounded-b-2 md:rounded-b-3';
		case 'no-structure':
			return '';
		default:
			return 'rounded-3xl p-1 md:p-2.5';
	}
};

const Card: FC<ICard.IProps> = ({
	variant = 'reverse',
	children,
	className = '',
	noBorder = false,
	padding = '',
	structure = 'default',
}) => (
	<div
		data-cy="cardContainer"
		className={`bg-card-primary ${padding} ${
			noBorder ? '' : 'border border-tone-contrast'
		} ${getColor(variant)} ${getStyle(structure)}  ${className}`}
	>
		{children}
	</div>
);

export default Card;
